import { CARE_PROGRAMS_GROUPS } from "../../../config/url.config";
import Services from "../../services";

const instance = new Services().getServiceInstance();


export const createGroups = async (params) => {
    return await instance.post(`${CARE_PROGRAMS_GROUPS}/group`, params)
        .then((res) => res)
        .catch((err) => err.response);
}
export const getAllGroups = async (key,page, limit) => {
    const params = {
        limit: limit || 10,
        skip: 0
      }
    if (page && limit) {
        params.skip = (page - 1) * limit
    }
    const data = await instance.get(`${CARE_PROGRAMS_GROUPS}/group`,{params})
    return data;
}

export const getGroupByID = async (key,groupId) => {
    const {data} = await instance.get(`${CARE_PROGRAMS_GROUPS}/group/${groupId}`)
    return data;
}

export const updateGroupByID = async (id, params) => {
    return await instance
      .patch(`${CARE_PROGRAMS_GROUPS}/group/${id}`, params)
      .then((res) => res)
      .catch((err) => err.response);
};
export const DeleteGroup = async (id) => {
    return await instance
      .delete(`${CARE_PROGRAMS_GROUPS}/group/${id}`)
      .then((res) => res)
      .catch((err) => err.response);
};

export const getGroupMembers = async (key,programId,id,role,search,page,rowsPerPage) => {
    let params = {
        careProgramId:programId,
        group:id,
        role: role,
        search : search,
        pageNumber:page,
        pageSize: rowsPerPage,
        skip: 0
    }
    if(role == ''){
        return []
    }
    const data = await instance.get(`${CARE_PROGRAMS_GROUPS}/groupParticipant`,{params})
    return data;
}

export const getCaseManagerMembers = async (groupId,participantType, viewMember, search, page) => {
    let params = {
        groupId: groupId,
        participantListType: participantType,
        practitionerId: viewMember,
        search: search,
        pageNumber:page,
        pageSize:1000,
        skip: (page - 1)*1000 ,
        limit: 1000
    }
    const data = await instance.get(`${CARE_PROGRAMS_GROUPS}/groupParticipantList?`,{params})
    return data;
}

export const updateCaseManager = async ( payload) => {
    const { data } = await instance.patch(`${CARE_PROGRAMS_GROUPS}/updateCaseManager`, payload);
    return data;
  };



export const deleteGroupMembers = async (participantId) => {
    const data = await instance.delete(`${CARE_PROGRAMS_GROUPS}/groupParticipant/${participantId}`)
    return data;
}

export const GetGroupByCareProgramId = async (key,{id,patientId,page,limit}) => {
    let params = {
        careProgramme:id,
        userId:patientId,
        limit: limit || 10,
        skip: 0,
        sortBy:''
    }
    if (page && limit) {
        params.skip = (page - 1) * limit
    }
    if(id){
        const data = await instance.get(`${CARE_PROGRAMS_GROUPS}/group`,{params})
        return data;
    }
}

export const createGroupsParticipant = async (params) =>{
    return await instance.post(`${CARE_PROGRAMS_GROUPS}/groupParticipant`, params)
        .then((res) => res)
        .catch((err) => err.response);
}

export const getMembersByCarePrograme = async (key,page, limit,id,role,search) => {
    let params = {
        careProgramme:id,
        role,
        limit: limit || 10,
        skip: 0,
        search
    }
    if (page && limit) {
        params.skip = (page - 1) * limit
    }
    const data = await instance.get(`${CARE_PROGRAMS_GROUPS}/getCareProgrammeParticipant`,{params})
    return data;
}

export const SearchGroup = async ({CareProgramId, patientId ,GroupName,page,limit}) => {
    let params = {
        careProgramme:CareProgramId,
        userId:patientId,
        search:GroupName,
        limit: limit || 10,
        skip: 0,
    }
    if (page && limit) {
        params.skip = (page - 1) * limit
    }
    const data = await instance.get(`${CARE_PROGRAMS_GROUPS}/group`,{params})
    return data;
}